#homepage
    .centered
        display: flex
        justify-content: center
        align-items: center

    .large-logo-container
        height: 50vh
        min-height: 400px !important
        @media (max-width: 500px)
            min-height: 300px !important
            width: 70%
        justify-content: center
        align-items: center
        display: flex
        padding: 2rem

    .video-container
        justify-content: center
        align-items: center
        display: flex
        pointer-events: none

    .video-react-big-play-button
        display: none
    
    .story-title
        font-weight: 100
        font-size: 4rem
        font-style: italic

    .primary-button
        text-transform: uppercase
        font-size: 1.5rem
        letter-spacing: 4px
        font-weight: 200

    .story-container
        margin: 3rem
        font-weight: 200
        font-style: italic

    .grid-fill-tile-bottom
        position: relative
        width: 100%
        height: 100%
        display: grid

        @media (min-width: 200px) 
            grid-template-columns: repeat(2, 1fr)
        @media (min-width: 1024px) 
            grid-template-columns: repeat(4, 1fr)

        grid-gap: 1rem
        grid-auto-flow: dense
        margin: 1rem auto

        img 
            display: block
            object-fit: cover
            width: 100%
            height: 100%

        *:nth-child(1)
            @media (min-width: 200px) 
                grid-column: span 2
            @media (min-width: 1024px) 
                grid-column: span 4  

    .grid-fill-tile
        position: relative
        width: 100%
        // height: 100%
        display: grid
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr))
        grid-gap: 1rem
        grid-auto-flow: dense
        margin: 1rem auto

        .grid-section
            position: relative
            margin-bottom: 4rem
            cursor: pointer

            .text-centered
                position: absolute
                bottom: 2.5rem
                width: 100%
                font-weight: 600
                text-align: center
                font-size: 1.5rem
                color: #fff
                @media (max-width: 768px)
                    font-size: 1rem
                    bottom: 1.5rem

            .small-underlined
                font-size: 0.6rem
                bottom: 3rem
                text-decoration: underline

                @media (max-width: 768px)
                    bottom: 2rem


        img
            display: block
            object-fit: cover
            width: 100%
            height: 100%

    .gapless
        grid-gap: 0    

    .grid-always-three 
        grid-template-columns: repeat(3, 1fr)

    .grid-always-two
        grid-template-columns: repeat(2, 1fr)

    .grid
        display: grid
        grid-template-columns: repeat(6, 1fr)
        grid-gap: 1rem

        .grid-col-padding
            grid-column: span 1
            grid-row: span 6

        .tile:nth-child(2)
            grid-column: span 2
            grid-row: span 1
            justify-content: center
            margin-top: 8rem
            margin-bottom: 4rem
            @media (max-width: 650px)
                margin-top: 2rem
                margin-bottom: 4rem

        .tile:nth-child(3)
            grid-column: span 2
            grid-row: span 2
            min-width: 200px

        .tile:nth-child(4)
            grid-column: span 2
            grid-row: span 6
            min-width: 200px

        .blue-video
            div
                max-width: 100%
                width: 550px
                margin: 0 auto

        .white-video
            div
                max-width: 100%
                width: 350px
                margin: 0 auto
                
        @media (max-width: 650px)
            display: block
        
            .tile
                margin-bottom: 1rem

            .grid-col-padding
                display: none
    

#landingFullHeight
    width: 100%
    position: relative
    background-size: cover
    background-repeat: no-repeat
    height: calc(100vh - 185px)
    background-position: 50% 50%
    background-color: #777
    transform: scale(1.1)
    opacity: 0
    transition: all 0.75s cubic-bezier(0, 0.14, 0.19, 1) 0s !important
    .landing-container
        display: flex
        flex-direction: column
        justify-content: flex-start
        align-items: center
        .landing-text
            position: absolute
            bottom: calc(10%)
            left: 0%
            max-width: 25rem
            @media (min-width: 768px)
                left: 10%
                bottom: calc(25%)
                color: white
            @media (min-width: 1024px)
                left: 67%
                top: calc(17%)
                max-width: 26rem
                color: black
            @media (min-width: 1224px)
                left: 67%
                top: calc(17%)
                max-width: 26rem
                color: black
            font-size: 3rem
            color: white
            padding: 2rem
            font-family: Modernline
            p
                font-size: 1rem
                @media (min-width: 350px) and (min-height: 510px)
                    font-size: 2rem
                @media (min-width: 768px) and (min-height: 675px)
                    font-size: 2.75rem
                text-align: left
                max-width: 55vw
            .landing-text-small
                margin-top: 1rem
                font-size: 0.55rem
                @media (min-width: 350px) and (min-height: 450px)
                    font-size: 0.85rem
                @media (min-width: 768px) and (min-height: 675px)
                    font-size: 1rem
                text-align: left
                max-width: 55vw
        .primary-button
            position: absolute
            bottom: 2rem
            font-size: 0.7rem
            @media (min-width: 450px) and (min-height: 450px)
                font-size: 0.85rem
            @media (min-width: 768px) and (min-height: 675px)
                font-size: 1rem
            font-weight: 600
            border: solid white 0.5px
    .hero-body
        padding: 0

.zoom-out
    transform: scale(1) !important
    opacity: 1 !important
    background-color: #999 !important

#landingCollectionCaption
    width: 100%
    position: relative
    background-size: cover
    background-repeat: no-repeat
    height: calc(45vh + 5px)
    background-position: 0% 50%
    background-color: transparent
    .landing-container
        display: flex
        flex-direction: column
        justify-content: flex-start
        align-items: center
        .landing-text
            position: absolute
            top: calc(15%)
            left: 2%
            font-size: 3rem
            color: white
            padding: 2rem
            font-family: Plak
            p
                font-size: 1.5rem
                text-align: left
        .primary-button
            position: absolute
            bottom: 4rem
            font-size: 1rem
            font-weight: 600
            border: solid white 0.5px
    .hero-body
        padding: 0

@mixin hover-state
        @media (hover: hover) and (pointer: fine) 
            &:hover 
                @content
        
.product-link-circle
    position: absolute
    width: 16px
    height: 16px
    margin: -8px 0 0 -8px
    background: #ffffff
    border-radius: 100%
    box-shadow: 0 1px 10px #00000040
    cursor: pointer
    z-index: 1
    transform: scale(1)
    transition: transform .25s ease-in-out

    &:after
        position: absolute
        content: ""
        width: 40px
        height: 40px
        left: -12px
        top: -12px
        border-radius: 100%
        background: rgba(255,255,255,.4)
        -webkit-animation: 1.4s shopTheLookDotKeyframe ease-in-out infinite
        animation: 1.4s shopTheLookDotKeyframe ease-in-out infinite
        