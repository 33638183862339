.container-full-width
    display: flex
    width: 100%
    justify-content: space-between
    align-items: center

    @media (max-width: 1024px)
        display: block

    .navbar-start
        margin-right: 0

    .navbar-brand
        @media (min-width: 1024px)
            position: absolute
            left: 45%

.left-nav-modal
    align-items: center
    flex-direction: row !important
    display: flex
    justify-content: flex-start !important
    overflow: hidden
    position: fixed
    z-index: 40
    width: 100%
    
.left-nav-modal-content
    width: 90% !important
    max-width: 400px
    margin: 0 !important

.left-nav-modal-box
    padding: 3rem 1rem
    height: 100vh
    background-color: #fff
    justify-content: space-between

#navbarMurashki
    flex-grow: 0
    .navbar-end
        margin-left: 0

.padded-header-guide
    opacity: 0.7
    font-size: 1.25rem

.navbar
    font-family: Roboto
    text-transform: uppercase
    letter-spacing: 2px
    font-size: 0.75rem !important
    position: sticky !important
    top: 0
    width: 100%
    background-color: rgba(250, 250, 250, 0.8) !important

    .navbar-content
        display: flex
        justify-content: space-between


    .logo
        margin-top: 0.75rem

    .burger
        color: hsl(0, 0%, 29%)
        cursor: pointer
        display: block
        height: 3.25rem
        position: relative
        width: 3.25rem

        span
            background-color: currentColor
            display: block
            height: 1px
            left: calc(50% - 8px)
            position: absolute
            transform-origin: center
            transition-duration: 86ms
            transition-property: background-color, opacity, transform
            transition-timing-function: ease-out
            width: 16px

        span:nth-child(1)
            top: calc(50% - 6px)
        span:nth-child(2)
            top: calc(50% - 1px)
        span:nth-child(3)
            top: calc(50% + 4px)
        

    .navbar-item
        @media (max-width: 1024px)
            padding: 10px 20px

    .cart-icon
        width: 35px
        height: 35px
        display: flex
        justify-content: center
        align-items: center
        font-size: 20px

    .badge
        width: 23px
        height: 23px
        background: #b96868a6
        position: absolute
        border-radius: 18px
        left: 35px
        top: 4px
        display: flex
        justify-content: center
        align-items: center
        color: white
        z-index: -2

    .badge-mobile
        width: 23px
        height: 23px
        background: #fb3157c2
        position: absolute
        border-radius: 18px
        left: 65px
        top: 8px
        display: flex
        justify-content: center
        align-items: center
        color: white
        z-index: 1

    .badge-burger
        width: 23px
        height: 23px
        background: #fb3157c2
        position: absolute
        border-radius: 18px
        right: 25px
        top: 5px
        display: flex
        justify-content: center
        align-items: center
        color: white
        z-index: -1
