#newTileForm
    position: fixed
    z-index: 20
    background-color: aliceblue
    display: flex
    flex-direction: column
    padding: 20px
    border-radius: 50px
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)
    @media (min-width: 1024px)
        bottom: 150px
        right: 150px
        width: 30%
        height: 50vh
    @media (max-width: 1024px)
        bottom: 20px
        right: 20px
        width: 90%
        height: 50vh

    .tile-types-container
        width: 100%
        display: flex
        flex-direction: row
        flex-wrap: wrap
        .tile-type-option
            height: 75px
            background: #fff
            border-radius: 10px
            margin: 10px
            padding: 5px
            display: flex
            justify-content: center
            align-items: center
            cursor: pointer