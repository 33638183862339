.collections-homepage
    @mixin hover-state
        @media (hover: hover) and (pointer: fine) 
            &:hover 
                @content
        
    .hero-body
        @media (max-width: 568px)
            padding: 1rem 0.5rem


.similar-items-container
    display: flex
    

.collection-container
    position: relative
    margin: 0 1.5rem
    display: grid
    gap: 1rem
    grid-auto-flow: dense

    @media (min-width: 200px) 
        grid-template-columns: repeat(2, 1fr)
    @media (min-width: 768px) 
        grid-template-columns: repeat(4, 1fr)

.product-container-complete-set
    position: relative
    display: grid
    gap: 1rem
    justify-items: center
    grid-auto-flow: dense

    @media (min-width: 200px) 
        grid-template-columns: repeat(2, 1fr)

.collection-container-complete-set
    position: relative
    display: grid
    gap: 1rem
    justify-items: center
    grid-auto-flow: dense

    @media (min-width: 200px) 
        grid-template-columns: repeat(2, 1fr)
    @media (min-width: 768px) 
        grid-template-columns: repeat(3, 1fr)
    @media (min-width: 1024px) 
        grid-template-columns: repeat(4, 1fr)

.collection-container-similar-items
    position: relative
    display: grid
    gap: 1rem
    justify-items: center
    grid-auto-flow: dense

    @media (min-width: 200px) 
        grid-template-columns: repeat(2, 1fr)
    @media (min-width: 768px) 
        grid-template-columns: repeat(3, 1fr)
    @media (min-width: 1024px) 
        grid-template-columns: repeat(4, 1fr)

    
#collections
    @mixin hover-state
        &:hover, &:active, &:focus
            @content

    .subtitle
        font-size: 1.25rem
        font-weight: 400
        line-height: 4
        text-transform: uppercase
        margin: 0

    .hero-inner
        min-height: 50vh
       
    .collection-container
        display: grid
        margin: 0 1rem
        display: grid
        gap: 1rem
        margin-bottom: 4rem
        @media (min-width: 200px) 
            grid-template-columns: repeat(2, 1fr)
        @media (min-width: 768px) 
            grid-template-columns: repeat(3, 1fr)
        @media (min-width: 1024px) 
            grid-template-columns: repeat(3, 1fr)

.collection-item-wrapper

    width: 100%
    cursor: pointer
    position: relative
   
    transition: 200ms
    // margin: 1%
    // width: 300px
   
    @media (max-width: 768px)
        // width: 48%
    @media (max-width: 600px)
        width: 100% !important
        // max-width: 450px
        justify-content: center
        // display: flex
        // flex-direction: column
        // align-items: center

    .pre-load-placeholder
        width: 100%
        display: block
        padding-top: 150%
        background-color: #c7c7c7
        position: absolute

    .secondary-preview-container
        div:nth-child(1)
            position: absolute !important

        .video-react
            background-color: #c7c7c7


    .image
        @include hover-state
            //opacity: 0.5
        cursor: pointer
        position: absolute

    .data-container
        margin-top: calc(150% + 1rem)
        width: 100%
        font-family: 'Roboto'
        display: flex
        flex-direction: column
        justify-content: space-between
        text-align: center
        .item-title
            height: 1rem
            margin-top: 5px
            font-size: 0.61rem
            @media (min-width: 765px)
                letter-spacing: 1px
            text-transform: uppercase
            font-weight: 400
            cursor: pointer

        .price
            font-size: 13px
            text-transform: uppercase
            font-weight: 400
            letter-spacing: 5px
            padding: 10px 0

        .item-title-price
            font-weight: 600
            padding: 0.25rem 0
        .colors
            margin-top: 13px
            display: flex
            max-width: 85px
            .color-sample
                width: 13px
                height: 13px
                margin-left: 10px
    

.collections-title
    font-weight: 100
    text-align: center

.collection-item-title
    text-transform: uppercase
    font-weight: 400