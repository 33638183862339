#announcement-bar
  background: black
  width: 100%
  height: 35px
  display: flex
  justify-content: center
  align-items: center
  color: white
  font-size: 0.7rem
  z-index: 30
  position: relative
  @media (min-width: 678px)
    font-size: 0.9rem