#story
    .title
        text-align: center
        font-family: Roboto
        text-transform: uppercase
        letter-spacing: 4px
        padding-top: 4rem
    p
        margin-bottom: 2px
        font-size: 1.2rem
        letter-spacing: 0px
        font-family: 'Abel', sans-serif

    .text-padded
        margin-top: 0
        
        @media (min-width: 768px)
            margin-top: 4rem
        .title-date
            padding-bottom: 6rem
            text-align: right
            font-size: 2rem
            font-family: Modernline

    .lower-image
        margin-top: 10rem
        @media (max-width: 768px)
            margin-top: 0rem

    .higher-image
        

    .width-60
        width: 60%
        @media (max-width: 768px)
            width: 100%
    .width-80
        width: 80%
        @media (max-width: 768px)
            width: 100%

    .bottom_image
        width: 90% 
        height: auto
        margin-top: -9rem
        margin-left: 20rem
        @media (max-width: 768px)
            width: calc(70vw - 2rem)
            height: auto
            margin-top: -9rem
            margin-left: 25vw 
            
    .pic_full_width_bottom
        margin-top: 6rem
        @media (max-width: 768px)
            margin-top: -4rem