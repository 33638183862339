#cart-list
    .list-of-cards
        max-height: calc(100vh - 3.25rem - 300px)
        overflow: auto
        z-index: 1
        position: relative
    
    .cart-elem
        position: relative
        max-width: 45rem
        margin: 1rem auto
        .cart-list-title
            cursor: pointer
            margin-right: 20px
            font-size: 1rem
            text-transform: uppercase
            font-weight: 100
            letter-spacing: 2px
            text-align: left
            padding-bottom: 0.3rem
        .cart-list-body
            display: flex
            flex-direction: row
            justify-content: space-between
            align-items: center
            padding-bottom: 0.5rem
            .price
                font-size: 1rem
                font-weight: 400
                width: 4rem
                text-align: right
            .picture
                padding: 1%
            .size
                text-transform: uppercase
                font-weight: 400
            .color-container
                display: flex
                flex-direction: row
                align-items: center
                text-transform: uppercase
                font-weight: 400
                font-size: 1rem
                @media (max-width: 1024px)
                    font-size: 0.5rem
            .color-sample
                width: 15px
                height: 15px
                margin-right: 10px

            .quantity-circle
                width: 25px
                height: 25px
                border-radius: 20px
                background-color: #CACACA
                display: flex
                justify-content: center
                align-items: center
                position: absolute
                left: 40px
                top: -7px
                color: #fff
                font-weight: 600


        .remove
            cursor: pointer
            position: absolute
            right: 0
            bottom: 0
            font-size: 0.7rem
    .total-container
        border-top: solid 1px #CDCDCD
        padding: 2vh 0
        text-align: right
        font-size: 13px
        font-weight: 600
        text-transform: uppercase
        letter-spacing: 3px
        max-width: 45rem
        margin: 0 auto
        
        .crossed
            text-decoration: line-through
            opacity: 0.5
        .promo-container
            display: flex
            flex-direction: row
            justify-content: space-evenly
            .promo
                max-width: 200px
    .proceed-button-container
        display: flex
        justify-content: center
        margin-bottom: 4rem