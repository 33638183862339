#productLanding
    display: flex
    flex-direction: column
    .text-data-layout
        display: flex
        flex-direction: column
        justify-content: center
        margin: 2rem 0
        @media (max-width: 1024px)
            margin: 1rem 0
        .title
            font-size: 3.2rem
            font-weight: 600
            line-height: 1.5
            font-family: Poppins
            @media (max-width: 1024px)
                font-size: 2.7rem

        .subtitle
            font-family: Poppins
            letter-spacing: 24px
            text-transform: uppercase
            line-height: 2.5rem
            margin: 2rem 0
            @media (max-width: 1024px)
                letter-spacing: 12px

    .pic-layout-2
        .column
            text-align: center
        img
            max-width: 450px  
        margin-bottom: 5rem

    
    .tall-with-small-overlay-text
        display: flex
        justify-content: center
        flex-direction: column
        align-items: center
        margin-bottom: 5rem
        .img-1
            max-width: 500px
        .img-2
            margin-top: -23rem
            max-width: 50%
            margin-left: 40rem
            @media (max-width: 1024px)
                margin-left: 11rem
                margin-top: -3rem
        .subtitle
            font-family: Poppins
            letter-spacing: 24px
            text-transform: uppercase
            line-height: 2.5rem
            margin: 4rem 0 0
            max-width: 700px
            text-align: center
            @media (max-width: 1024px)
                margin: 1rem 0 1rem
                letter-spacing: 12px

    .wide-horizontal-with-text-container
        height: 100vh
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        margin-bottom: 5rem
        @media (max-width: 1024px)
            margin: 3rem 0 3rem 0
        .img-1
            max-width: 650px
            width: 100%
        .subtitle
            font-family: Poppins
            letter-spacing: 24px
            text-transform: uppercase
            line-height: 2.5rem
            margin: 10rem 0 3rem
            max-width: 700px
            text-align: center
            @media (max-width: 1024px)
                margin: 3rem 0 3rem
                letter-spacing: 12px

    .fs-hero
        height: 100vh
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        margin-bottom: 5rem
        .img_1
            width: 100%
            @media (min-width: 1408px)
                width: 1344px
        .subtitle
            font-family: Poppins
            letter-spacing: 24px
            color: white
            text-transform: uppercase
            line-height: 2rem
            margin: -10rem 0 10rem
            max-width: 700px
            @media (max-width: 1024px)
                letter-spacing: 10px
                margin: -4rem 0 4rem
                font-size: 13px

    .columns-large-right-container
        margin-bottom: 6rem
        .column
            display: flex
            padding: 0.75rem
            flex-basis: 0
            flex-grow: 1
            flex-shrink: 1
            flex-direction: column
            justify-content: center
            align-items: center
        .img_1
            max-width: 500px
        .img_2
            max-width: 500px
        .subtitle
            font-family: Poppins
            letter-spacing: 24px
            text-transform: uppercase
            line-height: 2.5rem
            margin: 3rem 0 0
            max-width: 700px
            text-align: center

            

