#editLanding
    .landing-controls-container
        position: absolute
        top: 5px
        right: 0
        width: 150px
        display: flex
        justify-content: space-evenly
        background: brown
        color: white
        border-radius: 2px
        padding: 5px
        cursor: pointer
        .edit-container
            position: absolute
            background: #fff
            box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)
            padding: 20px
            z-index: 20
            @media (min-width: 1024px)
                top: 50px
                right: 100px
                width: 500px
            @media (max-width: 1024px)
                top: 50px
                right: 50px
                width: 90%

    .edit-landing-container
        position: relative

    .add-tile
        position: fixed
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)
        @media (min-width: 1024px)
            bottom: 100px
            right: 100px
        @media (max-width: 1024px)
            bottom: 20px
            right: 20px