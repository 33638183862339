#checkout
    margin-bottom: 2rem

    .title
        text-transform: uppercase
        letter-spacing: 4px
        font-size: 1.5rem
        margin-top: 3rem
        margin-bottom: 2rem
    .is-narrow
        // padding: 5vh 15%
        background: #fafafa
        padding: 0.75rem
        @media (max-width: 600px)
            padding: 2vh 2%
    .shipping-form
        max-width: 45rem
        margin: 1rem auto
        margin-bottom: 0.5rem
        font-family: Roboto

    .billing-form
        max-width: 45rem
        margin: 2rem auto

    .place-order-button
        width: 100%
        max-width: 750px
        font-size: 1.4rem
        @media (max-width: 768px)
            font-size: 1.2rem

    .checkout-button-container
        display: flex
        padding-bottom: 0.35rem
        justify-content: center
        margin: 10px 0px -15px 0px

    .step-indicator-container
        position: relative
        display: flex
        flex-direction: row
        justify-content: space-between
        .dash
            position: relative
            color: black
            font-size: 0.7rem
            font-weight: 600
            text-transform: uppercase
            letter-spacing: 6px

    .optionsList
        display: flex
        flex-direction: column
        justify-content: space-between
        align-items: center

    .shippingOption
        display: flex
        justify-content: space-between
        align-items: center
        border-bottom: 1px solid #DED5D5
        width: 100%
        padding-bottom: 0.5rem
        &:last-of-type
            border-bottom: none

    .shipping-description
        font-size: 0.8rem

    .indicator-container
        width: 5rem
        display: flex
        justify-content: center
        align-items: center

    .shipping-amt
        padding: 1rem

    .indicator
        width: 20px
        height: 20px
        border-radius: 50px
        margin: 1rem
        border: 1px solid $primary

    .is-selected
        border: 7px solid $primary

    .checkout-container
        display: flex
        width: 100%
        flex-wrap: wrap-reverse
        justify-content: center
        @media (min-width: 1200px)
            margin-left: 30px
        .stripeElements
            font-family: Roboto, Open Sans, Segoe UI, sans-serif
            margin: 0px 1rem
            
            

        .InputElement
            font-family: Roboto, Open Sans, Segoe UI, sans-serif !important
            

        

    .payment-separator
        display: -webkit-box
        display: -webkit-flex
        display: -ms-flexbox
        display: flex
        -webkit-box-align: center
        -webkit-align-items: center
        -ms-flex-align: center
        align-items: center
        -webkit-box-pack: justify
        -webkit-justify-content: space-between
        -ms-flex-pack: justify
        justify-content: space-between
        font-size: 0.8571428571em
        line-height: 1
        text-align: center
        text-transform: uppercase
        color: #737373
        margin: 1em 0

        span
            width: 100%
            font-size: 1em
            font-weight: 500
            margin: 0
            display: flex
            width: 100%
            justify-content: center
            -webkit-box-align: end
            align-items: flex-end
            text-align: center

        span:before
            content: ''
            border-top: 1px #e6e6e6 solid
            border-bottom: 0
            height: 0.5em
            -webkit-box-flex: 1
            -webkit-flex: 1 0 2em
            -ms-flex: 1 0 2em
            flex: 1 0 2em
            border-right: 0
            margin-right: 1em

        span:after
            margin-left: 1em
            content: ''
            border-top: 1px #e6e6e6 solid
            border-bottom: 0
            height: 0.5em
            flex: 1 0 2em
        
    h2
        width: 100%
        font-size: 1em
        font-weight: 500
        margin: 0
        display: flex
        width: 100%
        justify-content: center
        -webkit-box-align: end
        align-items: flex-end
        text-align: center
        color: #737373

        
    .express-checkout
        margin-top: 2rem
        h2:before
            content: ''
            border: 1px #e6e6e6 solid
            border-bottom: 0
            height: 0.5em
            -webkit-box-flex: 1
            -webkit-flex: 1 0 2em
            -ms-flex: 1 0 2em
            flex: 1 0 2em
            border-right: 0
            border-top-left-radius: 5px
            margin-right: 1em

        h2:after
            border-top-right-radius: 5px
            margin-left: 1em
            content: ''
            border-top: 1px #e6e6e6 solid
            border-right: 1px #e6e6e6 solid
            border-bottom: 0
            height: 0.5em
            flex: 1 0 2em

    .paypal-container  
        border: 1px #e6e6e6 solid
        border-top: 0
        border-bottom-left-radius: 5px
        border-bottom-right-radius: 5px
        padding: 0.5rem 1rem 1rem 1rem 
        div
            display: flex
            justify-content: center

.country-select-dropdown
    width: calc(100% - 5px) !important
    margin-right: 5px !important
    margin-bottom: 5px
    border-radius: 5px
    border: 1px solid #e6e6e6
    height: 3.25rem !important
    -webkit-appearance: none !important
    appearance: none !important

.state-select-dropdown
    width: calc(100% - 5px) !important
    margin-right: 5px
    border-radius: 5px
    border: 1px solid #e6e6e6
    height: 2.8rem !important
        