#admin
    .admin
        margin-top: 2rem
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        flex: 1

        .existing-items-container
            display: flex
            flex-direction: row
            flex-wrap: wrap
            width: 100%
            justify-content: center

        .size-container-wrap
            display: flex
            flex-direction: row
            flex-wrap: wrap
            
            .size
                padding: 10px
                min-width: 50px
                display: flex
                margin: 10px 10px 10px 0
                border: solid 3px #090
                justify-content: center
                align-items: center
                width: 50px
                height: 50px
                &.is-sold-out
                    border: solid 1px #cccccc

            .color
                display: flex
                padding: 8px
                height: 50px
                margin: 10px 10px 10px 0

    .item-data-container
        display: flex
        flex-direction: column
        background: #fafafa
        margin-top: 1rem
        width: 100%
        position: relative
        padding: 1rem

        .buttons
            padding: 2rem

        .edit-button
            position: absolute
            right: 10px
            top: 10px
            cursor: pointer
        
        .submit
            position: absolute
            right: 10px
            top: 10px
            cursor: pointer
            z-index: 10

        .control
            padding-bottom: 1rem
        
    .new-item-form
        display: flex
        flex-direction: column
        width: 75%

    .admin-page-selector
        text-align: center
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)
        margin: 40px
        border-radius: 10px
        &.is-active
            background-color: #fbb084

    .shipping-options-container
        display: flex
        flex-wrap: wrap

        .shipping-option
            background-color: #DCDCDC
            display: flex
            flex-direction: column
            width: 200px
            margin: 2rem
            border-radius: 20px
            padding: 0.5rem
            &.is-selected
                background-color: #CEFCBA

    .order-container
        width: 100%
        margin: 20px
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)
        padding: 1rem
        border-radius: 10px
        background-color: #fafafa
        
        overflow: auto
        background-color: #CEFCBA

        &.is-return
            background-color: pink

        &.is-processed
            background-color: white

        .name-wrapper
            display: flex
            flex-direction: row
            justify-content: space-between
        
            &.processed
                padding-bottom: 1rem

        .order-cart-container
            display: flex
            flex-direction: row
            padding-bottom: 1rem
            p
                padding-right: 0.25rem
            

    .details-part-container
        display: flex
        input
            margin-right: 10px
            margin-bottom: 10px
        textarea
            margin-right: 10px
            margin-bottom: 10px
            min-width: 0

    .new-desc-form
        input
            max-width: 200px
            margin-right: 20px

    .published-checkbox
        position: absolute
        right: 15px
        top: 7px
        label
            input
                margin-right: 5px
#category-admin
    .box
        display: flex
        flex-direction: column
        justify-content: space-evenly

    .editable-category
        background: #fafafa
        padding: 20px
        display: flex
        flex-direction: row
        justify-content: space-between

#promo-admin
    .box
        height: 250px
        display: flex
        flex-direction: column
        justify-content: space-evenly
    .editable-promo
        background: #fff
        padding: 20px
        border-radius: 10px
        margin: 10px
        cursor: pointer