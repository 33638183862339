@charset "utf-8"
@import 'bulma/sass/utilities/initial-variables.sass'
@import 'bulma/sass/utilities/functions.sass'
@import colors
@import navbar
@import homepage
@import product
@import collections
@import cart
@import cartList
@import checkout
@import admin
@import productLanding
@import editLanding
@import newTileForm
@import info
@import footer
@import story
@import announcement
@import navigation
@import stripeElements
@import '~video-react/styles/scss/video-react'
@import 'bulma/bulma.sass'
@import 'bootstrap/scss/_mixins.scss'
@import 'bootstrap/scss/_functions.scss'
@import 'bootstrap/scss/_variables.scss'
@import 'bootstrap/scss/forms/_floating-labels.scss'
@import 'bootstrap/scss/forms/_input-group.scss'
@import 'bootstrap/scss/_dropdown.scss'
@import 'bootstrap/scss/forms/_form-select.scss'
@import 'bootstrap/scss/_buttons.scss'
@import 'bootstrap/scss/_containers.scss'

.form-select 
    color: #737373

.slide-left
    position: absolute
    left: -100px
    -webkit-animation: slide1 0.2s forwards
    animation: slide1 0.2s forwards

    @-webkit-keyframes slide 1
        100% 
            left: 0

    @keyframes slide1 
        100% 
            left: 0

.slide-right
    position: absolute
    right: -100px
    -webkit-animation: slide 0.2s forwards
    animation: slide 0.2s forwards

    @-webkit-keyframes slide 
        100% 
            right: 0


    @keyframes slide 
        100% 
            right: 0


html 
  height: -webkit-fill-available

body
    position: relative !important

#root 
  min-height: 100vh
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available
 
.loading
    opacity: 0.5
    cursor: wait
    pointer-events: none

.modal-background
    overflow: hidden

.fullheight-overlay
    min-height: 100dvh
    height: 100%
    max-height: 100dvh

.form-floating
    width: 100%
    padding: 0px 5px 5px 0px
    color: #737373

.form-floating > .form-control
    border-radius: 5px
    border: 1px solid #e6e6e6
    width: 100%
    height: calc(2.5rem + 5px)

.form-floating > label
    padding: 0.5rem 0.75rem
    

=placeholder
    &::-webkit-input-placeholder
        @content
    &:-moz-placeholder
        @content
    &::-moz-placeholder
        @content
    &:-ms-input-placeholder
        @content   
body
    font-family: 'Roboto'
    scrollbar-width: none

.overlay
    position: fixed
    top: 0
    height: 100vh
    width: 100vw
    background: #BCBCBC
    opacity: 0.5
    display: flex
    justify-content: center
    align-items: center
    z-index: 100


#with-nav
    margin-top: 3.25rem

.sign-in
    justify-content: center
    align-items: center
    display: flex
    padding: 1rem

.hero-body
    @media (max-width: 568px)
        padding: 3rem 0.5rem
        overflow: auto

.image-upload
    width: 200px
    height: 50px
    border: 2px dashed #f55
    display: flex
    justify-content: center
    align-items: center
    margin: 10px 0
    background: #cdcdcd
    position: relative
    .input-file
        position: absolute
        margin: 0
        padding: 0
        width: 100%
        height: 100%
        outline: none
        opacity: 0

::-webkit-scrollbar 
    display: none


.large-screen-only
    @media (max-width: 450px)
        display: none
    img
        height: 100%
        object-fit: cover
        max-width: 100%

.img-promo
    width: 100%
    height: 50vh
    object-fit: cover

.img-promo-large
    object-fit: cover
    object-position: top

.img-promo-lingerie
    max-height: 200px
    height: 50%

.promo-text-large
    font-family: Plak
    font-size: 1.25rem
    padding-top: 0.25rem
    @media (max-width: 1100px)
        font-size: 1rem

.input
    +placeholder 
        color: #666666

.promo-text-small
    font-family: Plak
    font-size: 1rem
    padding-top: 0.15rem
    @media (max-width: 1100px)
        font-size: 0.75rem
    
.get-notified-modal
    margin: 3rem
    @media (max-width: 768px)
        margin: 1rem

.video-react-big-play-button
    display: none !important

.divider
    width: 100%
    border-bottom: 0.1rem solid #EBEBEB