#stripeElements
    // width: 100%
    // max-width: 550px

    .main
        max-width: 550px

    .lingerie-logo
        @media (max-width: 1024px)
            display: none


    .content-box
        border-radius: 5px
        border: 1px solid #e6e6e6
        padding-top: 0.75rem
        padding-bottom: 0.75rem
        margin-bottom: 1rem

        .contact-info

        .address

        .content
            font-size: 0.9rem
            display: flex
            flex-direction: row
            justify-content: space-between
            margin-left: 0.75rem
            margin-right: 0.75rem
            border-bottom: 1px solid #e6e6e6
            padding-top: 0.75rem
            margin-bottom: 0 !important
            &:last-of-type
                border: none
                margin-bottom: 0

            a
                color: #DED5D5

            .info
                opacity: 0.65
                padding-right: 0.5rem
                color: #737373
                min-width: 4rem

            .value
                padding-right: 0.5rem
                color: #737373
                width: 80%

.stripeElements
    =placeholder
        &::-webkit-input-placeholder
            @content
        &:-moz-placeholder
            @content
        &::-moz-placeholder
            @content
        &:-ms-input-placeholder
            @content     

    .FormGroup 
        padding: 0
        border-style: none
        // background-color: #fff
        will-change: opacity, transform
        // box-shadow: 0 6px 9px rgba(50, 50, 93, 0.05), 0 2px 5px rgba(0, 0, 0, 0.04)
        border-radius: 4px
        transition: all 0.2s ease-out

    .FormRow 
        display: -ms-flexbox
        display: flex
        -ms-flex-align: center
        align-items: center
        margin: 0 15px
        font-size: 16px
        font-family: Roboto, Open Sans, Segoe UI, sans-serif
        font-weight: 500
        font-style: normal
        text-rendering: optimizeLegibility
        border-bottom: 1px solid #E8E8E8

    .FormRowTax
        display: flex
        justify-content: space-between
        align-items: center
        margin: 0 15px
        font-size: 16px
        font-family: Roboto, Open Sans, Segoe UI, sans-serif
        font-weight: 500
        font-style: normal
        text-rendering: optimizeLegibility
        border-top: 1px solid #E8E8E8
    
    .FormRowLabel
        width: 22%
        @media (max-width: 1200px)
            width: 35%
        @media (max-width: 768px)
            width: 50%
        min-width: 90px
        padding: 11px 0
        color: #A9A9A9
        font-weight: 600
        font-size: 15px
        overflow: hidden
        font-smoothing: 'antialiased'
        text-overflow: ellipsis
        white-space: nowrap
        letter-spacing: 1.5px

    .FormRowLabelTax
        padding: 11px 0
        color: #505050
        font-weight: 600
        font-size: 18px
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap
        letter-spacing: 2px
    
    .FormRowLabelTaxSmall
        padding: 9px 0
        color: #505050
        font-weight: 600
        font-size: 13px
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap
        letter-spacing: 1px

    .FormRowShippingOption
        padding-top: 0.5rem
        padding-bottom: 0.25rem
        color: #505050
        font-weight: 600
        font-size: 15px
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap
        letter-spacing: 1px

    .FormRowInput 
        font-family: Roboto, Open Sans, Segoe UI, sans-serif
        font-size: 16px
        width: 100%
        padding: 11px 15px 11px 0
        color: #505050
        background-color: transparent
        animation: 1ms void-animation-out
        outline: none
        border-style: none
        +placeholder 
            opacity: 1
            font-family: Roboto, Open Sans, Segoe UI, sans-serif
            font-size: 16px
            color: #505050
            font-weight: 700
            -webkit-font-smoothing: antialiased
            line-height: 1.2em


    .StripeElement--webkit-autofill 
        background: transparent !important


    .StripeElement 
        width: 100%
        padding: 11px 15px 11px 0

    &.sidebar
        position: relative

        @media (min-width: 1200px)
            &:after
                content: ""
                background: #fafafa
                left: 0
                background-position: left top
                display: block
                width: 300%
                position: absolute
                top: 0
                left: -1rem
                bottom: 0
                background: #fcfcfc
                z-index: -1
                -webkit-box-shadow: 0 -1px 0 #e1e1e1 inset
                box-shadow: 1px 1px #f3f3f3 inset
                border-left: solid 1px #d4d4d440
                height: 100vh