#footer
    =placeholder
        &::-webkit-input-placeholder
            @content
        &:-moz-placeholder
            @content
        &::-moz-placeholder
            @content
        &:-ms-input-placeholder
            @content     

    padding: 3rem 1.5rem 1rem
    font-family: Roboto
    text-transform: uppercase
    letter-spacing: 2px

    .padded-social-icon
        margin: 20px
        cursor: pointer
    .padded-payment-method-icon
        margin: 20px
        
    .padded-title
        margin-bottom: 0.75rem
        font-weight: 600
        letter-spacing: 2px


    .bold
        font-weight: 600
        letter-spacing: 0.5px


    .input
        +placeholder 
            color: #333333
            padding-left: 5px
        color: black
        // background-color: #999999

    .newsletter-button
        font-family: Roboto
        text-transform: uppercase
        letter-spacing: 4px
        margin: 1rem
        font-weight: 600

    .email
        font-size: 0.6rem

    .legal-container
        display: flex
        flex-wrap: wrap
        width: 100%
        justify-content: center

        .legal-link
            font-size: 0.5rem
            opacity: 0.8
            padding-right: 0.5rem