@mixin on-hover 
    @media (hover: hover) and (pointer: fine) 
        &:hover 
            @content
        
.carousel-placeholder
    background: #c7c7c7
    padding-top: 150%

.size-selector-container
    display: flex
    flex-direction: row
    flex-wrap: wrap
    width: 23rem
    @media (max-width: 568px)
        width: 100%
    padding: 10px 0
.size-option-wrapper
    margin: 15px 15px 10px 0
    @media (max-width: 768px)
        margin: 0px 15px 0px 0
.size-option
    cursor: pointer
    min-width: 40px
    padding: 10px
    height: 30px
    display: flex
    justify-content: center
    align-items: center
    border: solid 1px #e0e0e0
    font-size: 12px
    @include on-hover
        border: solid 2px $primary
    @media (max-width: 768px)
        font-size: 10px

    &.selected
        background: $primary
    &.sold-out
        opacity: 0.5
.sold-out-text
    padding-top: 3px
    font-size: 7px
    opacity: 0.5
    text-align: center
    letter-spacing: 1px
    text-transform: uppercase

#product
    .scroll-bar-visible
        &::-webkit-scrollbar 
            display: block !important
            -webkit-appearance: none !important
        
        &::-webkit-scrollbar-thumb 
            border-radius: 10px !important
            background-color: rgba(0, 0, 0, .5) !important
            -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5) !important
        
    .hero-body
        align-items: normal
    @mixin hover-state
        @media (hover: hover) and (pointer: fine) 
            &:hover
                @content
        

    margin-top: 1rem
    .top-row-container
        height: 100%
        @media (max-width: 568px)
            padding-top: 1rem
    .title
        font-size: 22px
        letter-spacing: 4px
        text-transform: uppercase
        font-weight: 400
        padding-top: 2vh
        margin-bottom: 1rem
        @media (max-width: 768px)
            margin-top: 0
            margin-bottom: 1rem
            padding-top: 0
            font-size: 1rem
            letter-spacing: 2px

    .marketing-list
        padding-top: 4rem
        padding-bottom: 3rem

    .price
        letter-spacing: 1px
        font-size: 1.25rem
        text-transform: uppercase
        font-weight: 600
        margin-bottom: 1rem

    .image-column
        display: flex
        justify-content: center
        align-items: flex-start
        @media (min-width: 768px)
            margin-top: 1.5rem
    .image-container
        width: 100%
        .main-image
            object-fit: contain
            width: 100%
            max-height: 100%
            height: 100%
        div:nth-child(1)
            div:nth-child(1)
                ul:nth-child(1)
                    background: #c7c7c7

    .column
        padding: 0.5rem

    .product-data-wrapper
        @media (max-width: 768px)
            display: flex
            flex-direction: column
            align-items: center
        @media (min-width: 768px)
            margin-left: 3rem
        ul
            width: 100%
            .desc-block-container
                cursor: pointer
                width: 23rem
                @media (max-width: 768px)
                    width: 100%
                transition: all 500ms ease
                &.is-active
                    font-weight: 600
                .description-title
                    font-family: Roboto
                    line-height: 2rem
                    display: flex
                    justify-content: space-between
                    border-top: solid 1px #dcdcdc
                    margin-bottom: 0
                    padding: 10px 0
                    font-size: 16px
                   
            
                .description                       
                    white-space: pre-wrap
                    font-family: Roboto
                    font-size: 15px
                    max-height: 0
                    overflow: auto
                    &.opacity-zero
                        opacity: 0
                    &.is-active
                        padding: 0 0 1rem 0
                        max-height: 300px

                    a
                        text-decoration: underline
                        opacity: 0.6

    .back-button
        letter-spacing: 4px
        text-transform: uppercase
        font-size: 8px

    .purchasing-options-container
        width: 100%
        @media (max-width: 768px)
            display: flex
            flex-direction: column
            align-items: center

    .container-sizing
        width: 100%
        @media (min-width: 568px)
            max-width: 23rem
            display: flex
            flex-direction: column
        @media (max-width: 768px)
            display: flex
            flex-direction: column
            align-items: flex-start
            margin: 0 auto
        a
            float: left
            text-decoration: underline
            color: #777777
    .info-selection
        display: flex
        flex-direction: row
        justify-content: space-between
        width: 23rem
        @media (max-width: 768px)
            width: 100%

    .add-to-cart
        height: 40px
        border-radius: 0px
        @media (min-width: 568px)
            min-width: 23rem
        font-size: 1rem
        text-transform: uppercase
        @media (max-width: 568px)
            width: 100%
        margin-bottom: 2rem

    .dropdown
        display: flex
        margin: 1vh 0rem
        @media (max-width: 568px)
            width: 100%
        .dropdown-trigger
            width: 23rem
            @media (max-width: 568px)
                width: 100%
            .button
                min-width: 23rem
                width: 100%
        .dropdown-menu
            .dropdown-content
                min-width: 23rem
            .dropdown-item
                cursor: pointer

    .bottom-add-cart-container
        display: flex
        justify-content: center
        flex-direction: column
        align-items: center
        padding-top: 2rem

    .box
        .subtitle
            font-family: Poppins
            letter-spacing: 3px

    .color-selector-wrapper
        width: 23rem
        @media (max-width: 568px)
            width: 100%
        height: 40px
        margin: 10px 0
        text-transform: uppercase
        letter-spacing: 4px
        @media (max-width: 768px)
            letter-spacing: 2px
            height: 30px
            font-size: 10px

        font-size: 12px
        outline: solid 1px #e0e0e0
        @include on-hover
            outline: solid 1px $primary
        display: flex
        align-items: center
        position: relative
        cursor: pointer
        p
            padding-left: 24px
        .color-select-options
            position: absolute
            top: 40px
            width: 23rem
            @media (max-width: 568px)
                width: 100%
            z-index: 2
            // outline: solid 1px #e0e0e0

        .color-option
            height: 40px
            background: #fff
            display: flex
            align-items: center
            width: 23rem
            @media (max-width: 568px)
                width: 100%
            .color-title
                padding-left: 16px
                padding-right: 1rem
                display: flex
                flex-direction: row
                justify-content: space-between
                width: 100%

            .color-indicator
                width: 20px
                height: 20px
                margin-left: 16px
                @media (max-width: 768px)
                    width: 12px
                    height: 12px
        
        .toggle-arrow
            margin-right: 16px

    .product-placeholder
        margin-top: 3rem

        .hero-body
            padding-top: 1rem
        .container
            min-height: 100vh
        .image-column 
            @media (min-width: 768px)
                margin-top: 1.5rem
            .image-container
                background: #eaeae9
                width: 100%
                padding-top: 150%
                border-radius: 5px
                @media (max-width: 568px)
                    width: 100%
        .title
            background: #eaeae9
            padding-top: 2rem
            margin-top: 1rem
            width: 100%
        .price
            background: #eaeae9
            padding-bottom: 2rem
            margin-bottom: calc(1vh + 5px)
            width: 100%
        .sizing-guide
            background: #eaeae9
            padding-bottom: 0.5rem
            width: 100%
            margin-top: 2rem
        .desc-block-container
            margin-bottom: 2vh
            .description-title
                padding-bottom: 1rem
                background: #eaeae9
            .description
                background: #eaeae9
                padding-bottom: 1rem