#info
    min-height: calc(100vh - 385px)
    @media(max-width: 768px)
        margin-top: 2rem
    p
        font-size: 1.5rem
        margin-bottom: 2rem

    h1
        font-size: 3rem
        width: 100%
        text-align: center
        font-weight: 600
        margin-bottom: 2rem

    h2
        font-size: 2.5rem
        margin-bottom: 1.5rem
        font-weight: 600

    h3
        font-size: 2rem
        margin-bottom: 1rem
        font-weight: 600

    h4 
        font-size: 1.75rem
        margin-bottom: 1rem
        font-weight: 600

    .buttons
        margin-top: 2rem
    
    .info-text
        padding: 2rem
        @media(max-width: 768px)
            padding: 0.5rem
            
        font-size: 1rem
        h4
            font-size: 1rem
        h3
            font-size: 1rem
        p
            font-size: 0.75rem
            margin-bottom: 1rem
        ul
            font-size: 0.75rem
            list-style: inside

#newsletter-editor 
    display: flex
    flex-direction: column
    gap: 1rem

    p
        font-size: 1rem
        margin-bottom: 2rem

    h1
        font-size: 2rem
        width: 100%
        text-align: center
        font-weight: 600
        margin-bottom: 2rem

    h2
        font-size: 1.75rem
        margin-bottom: 1.5rem
        font-weight: 600

    h3
        font-size: 1.5rem
        margin-bottom: 1rem
        font-weight: 600

    h4 
        font-size: 1.25rem
        margin-bottom: 1rem
        font-weight: 600

    .buttons
        margin-top: 2rem

    .editor-container 
        display: flex
        gap: 1rem

        textarea 
            flex: 1
            height: 300px
        

        .markdown-preview 
            flex: 1
            padding: 1rem
            border: 1px solid #ccc
            overflow-y: auto
            height: 300px

    .info-text
        padding: 2rem
        @media(max-width: 768px)
            padding: 0.5rem
            
        font-size: 1rem
        h4
            font-size: 1rem
        h3
            font-size: 1rem
        p
            font-size: 0.75rem
            margin-bottom: 1rem
        ul
            font-size: 0.75rem
            list-style: inside
        
    

#info-admin
    .editable-info
        background: #fff
        padding: 20px
        border-radius: 10px
        margin: 10px
        cursor: pointer