#detailedCart
    @mixin hover-state
        
    display: flex
    flex-direction: column

    p
        display: flex

    .detailed-cart-container
        width: 300px
        max-height: 500px
        overflow: auto
        background: white
        position: absolute
        top: 20px
        left: 20px
        border-radius: 5px
        box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08)
        transform: translate(-100%, 0%)
        overflow: auto

    .cart-item-wrapper
        position: relative
        border-bottom: solid 1px #ded5d5
        padding-bottom: 0.5rem
        margin: 0.5rem
        .cart-item-data-container
            display: flex
            margin-bottom: 0.75rem
            flex-direction: row
            width: 100%
            align-items: center
            .data-wrapper
                display: flex
                flex-direction: row
            .data-container
                display: flex
                padding-left: 10px
                flex-direction: column
                .size-color
                    display: flex
                    flex-direction: column
                    justify-content: space-between
                .price
                    font-family: Roboto
                    font-size: 1rem
                    font-weight: 600

    .title-picture
        display: flex
        flex-direction: column
        justify-content: space-between
        height: 100%
        img
            max-height: 300px

    .color-sample
        width: 15px
        height: 15px
        margin-left: 10px

    .checkout
        border-radius: 0
        position: sticky
        bottom: 0
        left: 0
        width: 300px

    .empty
        display: flex
        justify-content: center
        align-items: center
        margin: 35px

    .remove
        cursor: pointer
        position: absolute
        right: 10px
        top: 10px


.cart-modal
    align-items: center
    flex-direction: row !important
    display: flex
    justify-content: flex-end !important
    overflow: hidden
    position: fixed
    z-index: 40
    width: 100%
    
.cart-modal-content
    width: 90% !important
    max-width: 400px
    margin: 0 !important

.cart-modal-box
    padding: 1rem
    min-height: 100dvh
    height: 100%
    background-color: #fff
    justify-content: space-between

.cart-items-wrapper
    display: flex
    flex-direction: column
    flex-grow: 1
    overflow: scroll

.remove-from-cart
    text-decoration: underline
    cursor: pointer

.subtotal-container
    display: flex
    flex-direction: row
    justify-content: space-between
    font-weight: 600
    padding-top: 0.75rem
    border-top: 1px solid black

.quantity-controls
    display: flex
    flex-direction: row
    -webkit-user-select: none
    -ms-user-select: none
    user-select: none

.quantity-indicator
    height: 30px
    width: 30px
    background-color: #CDCDCD
    display: flex
    justify-content: center
    align-items: center

.quantity-indicator:nth-child(1)
    cursor: pointer
    border-radius: 4px 0 0 4px

.quantity-indicator:nth-child(3)
    cursor: pointer
    border-radius: 0 4px 4px 0

